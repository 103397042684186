import React, { useEffect } from "react"
import Layout from "../../components/layout"
import { Link, graphql } from "gatsby";
import '../../styles/Article.css'
import { DefaultGtag } from '../../utils/misc'


const mainStyles = {
  display: "flex",
  flexDirection: "column"
}

const ArticlePage = () => {
  useEffect(() => {
    setTimeout(function () {
      // need delay, otherwise not working on firefox...
      window.scrollTo({ top: 0, left: 0, behavior: 'instant' });
    }, 100);
  }, [])

  return (
    <Layout>
      <main style={mainStyles}>
        <div className="blogpage">
          <div className="blog">
            <h1 className="title">How to cope with inflation in your restaurant? Part 1</h1>
            <p>If you're like most restaurateurs, you're concerned about the rising cost of ingredients and labor. But what can you do to keep your business afloat in an inflation-racked economy? </p>
            <p>In this Part 1 of our series on inflation, we'll look at a simple tool that you can use right now to fight inflation.</p>

            <h2>What is inflation?</h2>
            <p>Inflation is a general term that refers to the increase in the price of goods and services in an economy over time. Inflation can be caused by many factors, including a rise in the money supply, increased demand, or a decrease in the availability of goods or services.</p>
            <p>In the last year, restaurateurs have been hit hard by inflation. Here at Take a Seat, we have noticed that <b>many of our customers adjust their prices on a monthly, weekly of even daily basis!</b> As you can see, you're not alone in this boat, which is why we've designed digital menus that are easy to update.</p>

            <h2>Digital menus, a powerful weapon to manage inflation.</h2>
            <p>Digital menus are one of the secret weapons to manage inflation. By using digital menus, restaurants can track their menu prices and adjust them with a single click. Plus, because the menus are digital, so you don't need to reprint them and updates are instantly visible to your customers!</p>
            <p>With advanced tools, you can even decide to increase all prices by the same percentage at once. For example, <b>"Increase all prices by 5%"</b> and voilà, your new prices are live.</p>
            <p>Inflation is already a burden on your business, don't let it distract you from your core business by having to edit and reprint your menus. Take advantage of digital menus to save time and money.</p>

            <h2>Conclusion</h2>
            <p>If you own or manage a restaurant, then you are undoubtedly aware of the fact that inflation is a problem that restaurants face on a regular basis. There are a few things that you can do to help manage inflation and keep your business afloat. In this Part 1, we saw how you can easily update your prices and pass your costs increase along to your customers while minimizing your operational effort. Finally, make sure that you are able to stay on top of the trends in the restaurant industry. This means that you need to be aware of what is happening in the industry and be able to make adjustments accordingly.</p>

            <br />
            <p>We hope that you found this article useful. If you have any questions, feel free to <Link to="/contact/" style={{ color: "blue" }}>contact us</Link> and on social platforms: <a style={{ color: "blue" }} href="https://www.facebook.com/takeaseat.io" rel="noreferrer">Facebook</a>, <a style={{ color: "blue" }} href="https://twitter.com/Takeaseat_io" rel="noreferrer">Twitter</a> and <a style={{ color: "blue" }} href="https://www.linkedin.com/company/takeaseat-io" rel="noreferrer">LinkedIn</a> .</p>
            <p style={{ paddingBottom: "3rem" }}>Your <a href='https://takeaseat.io' rel="noreferrer">Take a Seat</a> team</p>
          </div>
        </div>
      </main>
    </Layout >
  )
}

export default ArticlePage

export const Head = () => <>
  <DefaultGtag />
  <meta property="og:type" content="website" />
  <meta property="og:image" content="https://takeaseat.io/static/media/images/all-in-one-6" />
  <meta property="og:site_name" content="Blog of Take a Seat" />
  <meta name="twitter:card" content="summary" />
  <meta name="twitter:image" content="https://pbs.twimg.com/media/FEjpZgbXIAoTpq4?format=jpg&name=4096x4096" />
  <meta name="twitter:site" content="@Takeaseat_io" />
  <meta name="twitter:creator" content="@Takeaseat_io" />
  <link rel="canonical" href="https://takeaseat.io/article/how-to-cope-with-inflation-part-1/" />
  {/* <link rel="alternate" hreflang="x-default" href="https://takeaseat.io/article/how-to-cope-with-inflation-part-1/" />
  <link rel="alternate" hreflang="fr" href="https://takeaseat.io/fr/article/how-to-cope-with-inflation-part-1/" />
  <link rel="alternate" hreflang="de" href="https://takeaseat.io/de/article/how-to-cope-with-inflation-part-1/" />
  <link rel="alternate" hreflang="es" href="https://takeaseat.io/es/article/how-to-cope-with-inflation-part-1/" /> */}
  <title>How to cope with inflation in your restaurant? Part 1</title>
  <meta name="description" content="In this first part of our series on inflation, we'll look at a simple tool, digital menus, that you can use right now to fight inflation." />
  <meta property="og:title" content="How to cope with inflation in your restaurant? Part 1" />
  <meta property="og:description" content="In this first part of our series on inflation, we'll look at a simple tool, digital menus, that you can use right now to fight inflation." />
  <meta property="og:url" content="https://takeaseat.io/article/how-to-cope-with-inflation-part-1/" />
  <meta name="twitter:title" content="How to cope with inflation in your restaurant? Part 1" />
  <meta name="twitter:description" content="In this first part of our series on inflation, we'll look at a simple tool, digital menus, that you can use right now to fight inflation." />
  <script type="application/ld+json">
    {JSON.stringify({
      "@context": "http://schema.org",
      "@type": "BlogPosting",
      "headline": "How to cope with inflation in your restaurant? Part 1",
      "author": "Take a Seat",
      "description": "In this first part of our series on inflation, we'll look at a simple tool, digital menus, that you can use right now to fight inflation.",
      "url": "https://takeaseat.io/article/how-to-cope-with-inflation-part-1/",
      "mainEntityOfPage": {
        "@type": "WebPage",
        "@id": "https://takeaseat.io/article/how-to-cope-with-inflation-part-1/"
      },
      "datePublished": "2022-05-30",
      "dateModified": "2022-09-30",
      "publisher": {
        "@type": "Organization",
        "name": "Take a Seat",
        "logo": {
          "@type": "ImageObject",
          "url": "https://takeaseat.io/media/icons/tas-230427.svg"
        }
      },
      "image": {
        "@type": "ImageObject",
        "url": "https://takeaseat.io/static/media/images/keyboard-gab90d7ee9_640.jpg"
      }
    })}
  </script>
</>

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {ns: {in: ["cookies","index", "navigation"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;